import React, { useState } from 'react'

import H5 from './H5'
import H6 from './H6'
import Paragraph from './Paragraph'
import Modal from './UnderstandTheDifferenceStyle'
import { Scrollbars } from 'react-custom-scrollbars';

const WannaBuyModal = ({
  visible,
  onClose
}) => {
  const [status, setStatus] = useState('default')
  const onCloseModal = () => {
    setStatus('default')
    onClose()
  }
  const statuses = {
    default: (
      <>
        <Scrollbars
          style={{ height: 400 }}
        >
          <H5
            selected
            on='onSurface'
            variant='highEmphasis'
            align='center'
            mb={[3]}
          >
            Qual estrutura escolher na HostDime?
          </H5>
          <Paragraph
            mb={[4]}
          >
            A HostDime, pensando na sua comodidade, preparou três diferentes estruturas de Data Center com níveis de atuação distintos. Tudo dependerá da necessidade da sua aplicação e da disponibilidade de investimento do seu projeto. Confira a diferença entre as plataformas:
          </Paragraph>
          <H6
            selected
            on='onSurface'
            variant='highEmphasis'
            align='center'
            mb={[2]}
          >
            NORDESTE
          </H6>
          <Paragraph
            mb={[4]}
          >
            Melhor conectividade para o Brasil do que o Data Center de Orlando-FL. É uma excelente opção para ser a sua primeira estrutura no Brasil ou caso a sua aplicação não necessite de baixíssima latência para a região Sul do Brasil. Destaca-se pela baixa latência para outros países e latência média para todo Brasil.
          </Paragraph>
          <H6
            selected
            on='onSurface'
            variant='highEmphasis'
            align='center'
            mb={[2]}
          >
            SÃO PAULO
          </H6>
          <Paragraph
            mb={[4]}
          >
            Perfeita para quem procura um serviço premium, com plena capacidade de operação e as melhores rotas de conexões. Servidores com máxima potência e rotas redundantes destacam esta estrutura. Além disto, toda a estrutura é 100% controlada, além de possuir a certificação internacional TIER-3 e SSAE-16, o que atesta a operação das plataformas.
          </Paragraph>
          <H6
            selected
            on='onSurface'
            variant='highEmphasis'
            align='center'
            mb={[2]}
          >
            ESTADOS UNIDOS
          </H6>
          <Paragraph>
            Estrutura ideal para quem deseja investir pouco e ter acesso a qualidade de ponta. Servidores robustos e conectividade abundante. Os serviços têm um menor preço, já que possuímos estrutura própria nos Estados Unidos, bem como o custo para aquisição de novos equipamentos é muito menor do que no Brasil.
          </Paragraph>
        </Scrollbars>
      </>
    ),
  }

  return (
    <Modal
      open={visible}
      hasArrowBack={['callForm', 'messageForm'].includes(status)}
      hideIcons={status === 'sent'}
      onClose={onCloseModal}
      onBack={() => setStatus('default')}
      style={{
        zIndex: 2000,
      }}
    >
      {statuses[status]}
    </Modal>
  )
}

export default WannaBuyModal
